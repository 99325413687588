// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-erp-index-js": () => import("./../../../src/pages/about-erp/index.js" /* webpackChunkName: "component---src-pages-about-erp-index-js" */),
  "component---src-pages-about-odoo-accounting-js": () => import("./../../../src/pages/about-odoo/accounting.js" /* webpackChunkName: "component---src-pages-about-odoo-accounting-js" */),
  "component---src-pages-about-odoo-crm-js": () => import("./../../../src/pages/about-odoo/crm.js" /* webpackChunkName: "component---src-pages-about-odoo-crm-js" */),
  "component---src-pages-about-odoo-index-js": () => import("./../../../src/pages/about-odoo/index.js" /* webpackChunkName: "component---src-pages-about-odoo-index-js" */),
  "component---src-pages-about-odoo-inventory-js": () => import("./../../../src/pages/about-odoo/inventory.js" /* webpackChunkName: "component---src-pages-about-odoo-inventory-js" */),
  "component---src-pages-about-odoo-pos-js": () => import("./../../../src/pages/about-odoo/pos.js" /* webpackChunkName: "component---src-pages-about-odoo-pos-js" */),
  "component---src-pages-about-odoo-sales-js": () => import("./../../../src/pages/about-odoo/sales.js" /* webpackChunkName: "component---src-pages-about-odoo-sales-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-blog-3-sach-food-js": () => import("./../../../src/pages/blog/3Sach-food.js" /* webpackChunkName: "component---src-pages-blog-3-sach-food-js" */),
  "component---src-pages-blog-csc-manufacturing-js": () => import("./../../../src/pages/blog/csc-manufacturing.js" /* webpackChunkName: "component---src-pages-blog-csc-manufacturing-js" */),
  "component---src-pages-blog-empire-candle-js": () => import("./../../../src/pages/blog/empire-candle.js" /* webpackChunkName: "component---src-pages-blog-empire-candle-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-drop-the-ball-js": () => import("./../../../src/pages/drop-the-ball.js" /* webpackChunkName: "component---src-pages-drop-the-ball-js" */),
  "component---src-pages-get-a-demo-index-js": () => import("./../../../src/pages/get-a-demo/index.js" /* webpackChunkName: "component---src-pages-get-a-demo-index-js" */),
  "component---src-pages-hero-js": () => import("./../../../src/pages/hero.js" /* webpackChunkName: "component---src-pages-hero-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-monterey-software-js": () => import("./../../../src/pages/monterey-software.js" /* webpackChunkName: "component---src-pages-monterey-software-js" */),
  "component---src-pages-odoo-solution-js": () => import("./../../../src/pages/odoo-solution.js" /* webpackChunkName: "component---src-pages-odoo-solution-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-what-is-erp-js": () => import("./../../../src/pages/what-is-erp.js" /* webpackChunkName: "component---src-pages-what-is-erp-js" */),
  "component---src-pages-what-we-do-index-js": () => import("./../../../src/pages/what-we-do/index.js" /* webpackChunkName: "component---src-pages-what-we-do-index-js" */)
}

